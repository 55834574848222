<template>
    <div class="box">
        <a-button type="primary">计划下单</a-button>
        <div class="send_way">
            <span style="font-weight: 700;
font-size: 14px;">寄件方式：</span>
            <a-button type="primary">{{'自送入仓'}}</a-button>
        </div>
        <div style="font-weight: 600;
font-size: 16px; margin-top:30px">物品信息</div>
        <div class="item_msg">
            <a-form-model layout="inline">
                <a-form-model-item label="预估重量：">
                    <a-input suffix="公斤" v-model="articles.user">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item label="预估体积：">
                    <a-input suffix="立方米" v-model="articles.sd">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item>
                    合计件数：{{'12'}}
                </a-form-model-item>
            </a-form-model>
        </div>
        <div class="tickets">
            <a-button type="primary">一票</a-button>
            <div class="ticket_title">
                <div style="width:5%"> 运单</div>
                <div style="width:10%"> 件数</div>
                <div style="width:15%"> 国家</div>
                <div style="width:30%"> 渠道</div>
                <div style="width:30%"> 备注</div>
            </div>

            <div class="ticket_input">
                <div style="width:5%"> 运单</div>
                <div style="width:10%">
                    <a-input></a-input>
                </div>
                <div style="width:15%">
                    <a-select @change="countryChange" style="width:100%">
                        <a-select-option v-for="(item, index) in countryData" :key="index" :value="item.id"
                            :label="item.name">{{ item.name }}</a-select-option>
                    </a-select>
                </div>
                <div style="width:30%">
                    <a-select style="width:100%">
                        <a-select-option v-for="(item, index) in channelData" :key="index" :value="item.id"
                            :label="item.name">{{ item.name }}</a-select-option>
                    </a-select>
                </div>
                <div style="width:30%">
                    <a-input></a-input>
                </div>
            </div>
            <div class="img_box">
                <span style="float:left">图片：</span>
                <div style="float:left;margin-left: 25px;">
                    <a-upload accept=".bmp,.gif,.png,.jpeg,.jpg" :beforeUpload="beforeUpload" name="image"
                        list-type="picture-card" class="avatar-uploader" :file-list="fileList" :action="action"
                        @preview="handlePreview" :headers="uploadHeaders" @change="handleChange">
                        <div>
                            <a-icon type="plus" />
                            <div class="ant-upload-text">上传图片</div>
                        </div>
                    </a-upload>
                </div>

            </div>
            <div align="center">
                <a-button-group>
                    <a-button @click="resetForm">重置</a-button>
                    <a-popconfirm title="确定修改?" ok-text="确定" cancel-text="取消" @confirm="submit">
                        <a-button type="primary">确定修改</a-button>
                    </a-popconfirm>

                </a-button-group>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        countryList,
        channelCountry
    } from "@/api/comm";
    import store from "@/store";

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
    export default {
        name: 'editDetail',
        data() {
            return {
                countryData: [],
                channelData: [],
                fileList: [],
                articles: {
                    user: '',
                    sd: ''
                },
                action: process.env.VUE_APP_API_URL + "/file/upload/image",
                uploadHeaders: {
                    "device-type": "web",
                    "ansuex-user-token": store.getters.getToken,
                },
            }
        },
        created() {
            countryList().then((res) => {
                this.countryData = res.data.list;
            });
        },
        methods: {
            countryChange(val) {
                channelCountry({
                    countryId: val,
                }).then((res) => {
                    this.channelData = res.data;
                });
            },
            resetForm(){

            },
            submit(){

            },
            beforeUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message.error("上传文件大小不能超过 10MB!");
                    return false;
                }
            },
            async handlePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            handleChange({
                fileList
            }) {
                this.fileList = fileList;
            },
        }
    }
</script>

<style scoped lang="less">
    .box {
        padding-left: 50px;
        padding-top: 48px;

        .send_way {
            margin-top: 35px;
        }

        .tickets {
            margin-top: 25px;
            width: 60%;

            .ticket_title {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
            }

            .ticket_input {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
            }
        }

        .img_box {
            margin-top: 30px;
            overflow: hidden;
        }
    }
</style>